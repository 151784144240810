var we = Object.defineProperty;
var be = (t, o, i) => o in t ? we(t, o, { enumerable: !0, configurable: !0, writable: !0, value: i }) : t[o] = i;
var re = (t, o, i) => be(t, typeof o != "symbol" ? o + "" : o, i);
import { useReducer as _e, useRef as Y, useMemo as Z, useCallback as L, useEffect as q, useState as J } from "react";
import { styled as z, css as R } from "styled-components";
const de = {
  error: void 0,
  response: void 0,
  isLoading: !1,
  state: "idle"
}, Ae = (t, o) => {
  var i;
  switch (o.type) {
    case "loading":
      return {
        ...t,
        response: (i = o.meta) != null && i.isStale ? t.response : void 0,
        error: void 0,
        isLoading: !0,
        state: "loading"
      };
    case "fetched":
      return {
        ...t,
        response: o.payload,
        isLoading: !1,
        state: "success"
      };
    case "error":
      return {
        ...t,
        error: o.payload,
        isLoading: !1,
        state: "error"
      };
    case "abort":
      return {
        ...t,
        isLoading: !1,
        state: "aborted"
      };
    case "reset":
      return de;
    default:
      return t;
  }
}, Pe = {
  isClean: !0,
  isCache: !0,
  isStale: !1,
  parseResponseOnError: !1,
  headers: { "Content-type": "application/json;charset=UTF-8" }
}, Oe = (t) => {
  const o = ge(t), [i, s] = _e(
    Ae,
    de
  ), d = Y({}), g = Y(null), f = Z(
    () => window.AbortController ? new window.AbortController() : void 0,
    []
  ), c = L(
    async (...b) => {
      var F;
      const {
        isClean: E,
        isCache: I,
        isStale: P,
        parseResponseOnError: M,
        adapter: x,
        ...W
      } = o, [T, D = {}] = b;
      if (!T) return;
      g.current = b, s({ type: "loading", meta: { isStale: P } });
      const j = d.current[T];
      if (j && ae(D))
        return s({ type: "fetched", payload: j }), j;
      try {
        const C = await fetch(T, {
          ...W,
          ...D,
          signal: f == null ? void 0 : f.signal
        });
        if (!C.ok) {
          if (M) {
            const U = await C.json();
            s({ type: "error", payload: U });
            return;
          }
          throw new Error(C.statusText);
        }
        if ((F = f == null ? void 0 : f.signal) != null && F.aborted) return;
        const k = await C.json(), v = "data" in k ? k : { data: k };
        let { data: G } = v;
        if (E) {
          const { decodeData: U } = await import("./decodeData-sQP2Fy6l.js");
          G = U(v.data);
        }
        return x && (G = x(G)), I && ae(D) && (d.current[T] = G), s({ type: "fetched", payload: G }), G;
      } catch (C) {
        if (Ee(C))
          return;
        s({ type: "error", payload: C });
      }
    },
    [f == null ? void 0 : f.signal, o]
  ), p = L(() => s({ type: "reset" }), []), h = L(
    (b) => {
      if (!g.current)
        return console.warn(
          "useFetch: fetch state wasn't initialized, make fetch before revalidate"
        ), Promise.resolve();
      const [E, I = {}] = g.current;
      return d.current[E] = void 0, c(E, { ...I, ...b });
    },
    [c, g]
  ), m = L(
    (...b) => {
      f == null || f.abort(...b), s({ type: "abort" });
    },
    [f]
  ), S = Z(
    () => ({
      ...i,
      reset: p,
      revalidate: h,
      abort: m
    }),
    [m, p, h, i]
  );
  return q(() => () => {
    m();
  }, [m]), [c, S];
};
function Be(t, o) {
  const i = ge(o), [s, d] = Oe(i);
  return q(() => {
    t && s(t);
  }, [s, t]), d;
}
const ge = (t = {}) => {
  const {
    isCache: o,
    isClean: i,
    isStale: s,
    parseResponseOnError: d,
    body: g,
    method: f,
    mode: c,
    headers: p,
    keepalive: h,
    cache: m,
    credentials: S,
    window: b,
    redirect: E,
    integrity: I,
    referrerPolicy: P,
    signal: M,
    adapter: x
  } = { ...Pe, ...t };
  return Z(() => ({
    isCache: o,
    isClean: i,
    isStale: s,
    parseResponseOnError: d,
    body: g,
    method: f,
    mode: c,
    headers: p,
    keepalive: h,
    cache: m,
    credentials: S,
    window: b,
    redirect: E,
    integrity: I,
    referrerPolicy: P,
    signal: M,
    adapter: x
  }), [
    g,
    m,
    S,
    p,
    I,
    o,
    i,
    s,
    h,
    f,
    c,
    d,
    E,
    P,
    M,
    b,
    x
  ]);
}, Ee = (t) => {
  const o = t;
  return (o == null ? void 0 : o.name) === "AbortError";
}, ae = (t) => !t.method || t.method.toLocaleUpperCase() === "GET", Se = z.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  min-height: 16px;
  font-size: 13px;
`;
z.div`
  display: flex;
`;
z.p`
  font-weight: 700;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0;
`;
z.div`
  display: flex;
`;
z(Se)`
  margin-bottom: 0;
  margin-top: 8px;
  min-height: 1em;
  font-size: 16px;
`;
z.div`
  display: flex;
  align-items: center;
`;
z.p`
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.1px;
  color: ${({ theme: t }) => t.colors.red50};
`;
R`
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  ${({ theme: { down: t } }) => t("tablet")} {
    font-size: 14px;
  }
`;
const pe = "0px 1px 8px rgba(0, 0, 0, 0.17)", me = "0px 4px 20px rgba(0, 0, 0, 0.07)", he = "0px 4px 28px rgba(0, 0, 0, 0.14)";
R`
  -webkit-filter: blur(0);
  will-change: filter;
  filter: drop-shadow(${pe});
`;
R`
  -webkit-filter: blur(0);
  will-change: filter;
  filter: drop-shadow(${me});
`;
R`
  -webkit-filter: blur(0);
  will-change: filter;
  filter: drop-shadow(${he});
`;
R`
  box-shadow: ${pe};
  -webkit-filter: blur(0);
  filter: blur(0);
`;
R`
  box-shadow: ${me};
  -webkit-filter: blur(0);
  filter: blur(0);
`;
R`
  box-shadow: ${he};
  -webkit-filter: blur(0);
  filter: blur(0);
`;
const He = (t = !1) => {
  const [o, i] = J(t), s = L(
    () => i((f) => !f),
    [i]
  ), d = L(() => i(!1), [i]), g = L(() => i(!0), [i]);
  return { isToggled: o, toggle: s, toggleFalse: d, toggleTrue: g };
}, Ke = (t, o) => {
  q(() => {
    const i = (s) => {
      Array.isArray(t) && t.some(
        (g) => !g.current || g.current.contains((s == null ? void 0 : s.target) || null)
      ) || !Array.isArray(t) && (!t.current || t.current.contains((s == null ? void 0 : s.target) || null)) || o(s);
    };
    return document.addEventListener("mousedown", i), document.addEventListener("touchstart", i), () => {
      document.removeEventListener("mousedown", i), document.removeEventListener("touchstart", i);
    };
  }, [t, o]);
}, Ye = () => {
  const [t, o] = J({
    width: 0,
    height: 0
  });
  return q(() => {
    const i = () => {
      o({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    return i(), window.addEventListener("resize", i), () => {
      window.removeEventListener("resize", i);
    };
  }, []), t;
}, ie = (t, o, i) => {
  if (!Object.prototype.hasOwnProperty.call(t, o))
    return null;
  const s = t[o];
  if (typeof s != "boolean" && !s)
    return null;
  if (Array.isArray(s)) {
    const g = [];
    return s.forEach((f) => {
      g.push(`${o}[]=${f}`);
    }), g.join("&");
  }
  return `${i ? i(o) : o}=${s}`;
}, Ze = (t, o = {}) => {
  const { pathParams: i, query: s, filterQuery: d } = o, g = [];
  return i && Object.entries(i).forEach(([f, c]) => {
    if (c === void 0) {
      t = t.replace(new RegExp(`/:${f}\\??`), "");
      return;
    }
    t = t.replace(new RegExp(`:${f}\\??`), String(c));
  }), s && Object.keys(s).forEach((f) => {
    const c = ie(s, f);
    c && g.push(c);
  }), d && Object.keys(d).forEach((f) => {
    const c = ie(
      d,
      f,
      (p) => `filter[${p}]`
    );
    c && g.push(c);
  }), g.length && (t += `?${g.join("&")}`), t;
}, Je = (t) => t === "Clever District" || t === "Clever Library" ? "Clever" : t === "ClassLink District" ? "ClassLink" : "Manual", Ie = 300;
function Xe(t, o = Ie) {
  let i;
  return (...s) => {
    clearTimeout(i), i = setTimeout(() => {
      t(s);
    }, o);
  };
}
let Ce = (t) => crypto.getRandomValues(new Uint8Array(t)), Ge = (t, o, i) => {
  let s = (2 << Math.log(t.length - 1) / Math.LN2) - 1, d = -~(1.6 * s * o / t.length);
  return (g = o) => {
    let f = "";
    for (; ; ) {
      let c = i(d), p = d;
      for (; p--; )
        if (f += t[c[p] & s] || "", f.length === g) return f;
    }
  };
}, Te = (t, o = 21) => Ge(t, o, Ce);
const xe = Te("1234567890abcdef", 14), ye = (t) => {
  if (!t) throw new Error("Application name is required");
  return `${t}-${xe()}`;
}, Ne = () => ye(X.e4), et = () => ye(X.seasons);
var X = /* @__PURE__ */ ((t) => (t.e4 = "elements", t.seasons = "ke", t))(X || {}), V = {}, B = {}, H = {}, oe;
function Le() {
  return oe || (oe = 1, function(t) {
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.default = void 0;
    var o = function() {
      for (var d = arguments.length, g = new Array(d), f = 0; f < d; f++)
        g[f] = arguments[f];
      if (typeof window < "u") {
        var c;
        typeof window.gtag > "u" && (window.dataLayer = window.dataLayer || [], window.gtag = function() {
          window.dataLayer.push(arguments);
        }), (c = window).gtag.apply(c, g);
      }
    }, i = o;
    t.default = i;
  }(H)), H;
}
var K = {}, se;
function ze() {
  return se || (se = 1, function(t) {
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.default = f;
    var o = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;
    function i(c) {
      return c.toString().trim().replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, function(p, h, m) {
        return h > 0 && h + p.length !== m.length && p.search(o) > -1 && m.charAt(h - 2) !== ":" && (m.charAt(h + p.length) !== "-" || m.charAt(h - 1) === "-") && m.charAt(h - 1).search(/[^\s-]/) < 0 ? p.toLowerCase() : p.substr(1).search(/[A-Z]|\../) > -1 ? p : p.charAt(0).toUpperCase() + p.substr(1);
      });
    }
    function s(c) {
      return typeof c == "string" && c.indexOf("@") !== -1;
    }
    var d = "REDACTED (Potential Email Address)";
    function g(c) {
      return s(c) ? (console.warn("This arg looks like an email address, redacting."), d) : c;
    }
    function f() {
      var c = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", p = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !0, h = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : !0, m = c || "";
      return p && (m = i(c)), h && (m = g(m)), m;
    }
  }(K)), K;
}
var ue;
function Re() {
  return ue || (ue = 1, function(t) {
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.default = t.GA4 = void 0;
    var o = f(Le()), i = f(ze()), s = ["eventCategory", "eventAction", "eventLabel", "eventValue", "hitType"], d = ["title", "location"], g = ["page", "hitType"];
    function f(a) {
      return a && a.__esModule ? a : { default: a };
    }
    function c(a, e) {
      if (a == null) return {};
      var n = p(a, e), r, u;
      if (Object.getOwnPropertySymbols) {
        var l = Object.getOwnPropertySymbols(a);
        for (u = 0; u < l.length; u++)
          r = l[u], !(e.indexOf(r) >= 0) && Object.prototype.propertyIsEnumerable.call(a, r) && (n[r] = a[r]);
      }
      return n;
    }
    function p(a, e) {
      if (a == null) return {};
      var n = {}, r = Object.keys(a), u, l;
      for (l = 0; l < r.length; l++)
        u = r[l], !(e.indexOf(u) >= 0) && (n[u] = a[u]);
      return n;
    }
    function h(a) {
      "@babel/helpers - typeof";
      return h = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(e) {
        return typeof e;
      } : function(e) {
        return e && typeof Symbol == "function" && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      }, h(a);
    }
    function m(a) {
      return E(a) || b(a) || W(a) || S();
    }
    function S() {
      throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
    }
    function b(a) {
      if (typeof Symbol < "u" && a[Symbol.iterator] != null || a["@@iterator"] != null) return Array.from(a);
    }
    function E(a) {
      if (Array.isArray(a)) return T(a);
    }
    function I(a, e) {
      var n = Object.keys(a);
      if (Object.getOwnPropertySymbols) {
        var r = Object.getOwnPropertySymbols(a);
        e && (r = r.filter(function(u) {
          return Object.getOwnPropertyDescriptor(a, u).enumerable;
        })), n.push.apply(n, r);
      }
      return n;
    }
    function P(a) {
      for (var e = 1; e < arguments.length; e++) {
        var n = arguments[e] != null ? arguments[e] : {};
        e % 2 ? I(Object(n), !0).forEach(function(r) {
          v(a, r, n[r]);
        }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(a, Object.getOwnPropertyDescriptors(n)) : I(Object(n)).forEach(function(r) {
          Object.defineProperty(a, r, Object.getOwnPropertyDescriptor(n, r));
        });
      }
      return a;
    }
    function M(a, e) {
      return j(a) || D(a, e) || W(a, e) || x();
    }
    function x() {
      throw new TypeError(`Invalid attempt to destructure non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
    }
    function W(a, e) {
      if (a) {
        if (typeof a == "string") return T(a, e);
        var n = Object.prototype.toString.call(a).slice(8, -1);
        if (n === "Object" && a.constructor && (n = a.constructor.name), n === "Map" || n === "Set") return Array.from(a);
        if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return T(a, e);
      }
    }
    function T(a, e) {
      (e == null || e > a.length) && (e = a.length);
      for (var n = 0, r = new Array(e); n < e; n++) r[n] = a[n];
      return r;
    }
    function D(a, e) {
      var n = a == null ? null : typeof Symbol < "u" && a[Symbol.iterator] || a["@@iterator"];
      if (n != null) {
        var r, u, l, y, w = [], _ = !0, A = !1;
        try {
          if (l = (n = n.call(a)).next, e !== 0) for (; !(_ = (r = l.call(n)).done) && (w.push(r.value), w.length !== e); _ = !0) ;
        } catch (O) {
          A = !0, u = O;
        } finally {
          try {
            if (!_ && n.return != null && (y = n.return(), Object(y) !== y)) return;
          } finally {
            if (A) throw u;
          }
        }
        return w;
      }
    }
    function j(a) {
      if (Array.isArray(a)) return a;
    }
    function F(a, e) {
      if (!(a instanceof e))
        throw new TypeError("Cannot call a class as a function");
    }
    function C(a, e) {
      for (var n = 0; n < e.length; n++) {
        var r = e[n];
        r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(a, G(r.key), r);
      }
    }
    function k(a, e, n) {
      return e && C(a.prototype, e), Object.defineProperty(a, "prototype", { writable: !1 }), a;
    }
    function v(a, e, n) {
      return e = G(e), e in a ? Object.defineProperty(a, e, { value: n, enumerable: !0, configurable: !0, writable: !0 }) : a[e] = n, a;
    }
    function G(a) {
      var e = U(a, "string");
      return h(e) === "symbol" ? e : String(e);
    }
    function U(a, e) {
      if (h(a) !== "object" || a === null) return a;
      var n = a[Symbol.toPrimitive];
      if (n !== void 0) {
        var r = n.call(a, e);
        if (h(r) !== "object") return r;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return (e === "string" ? String : Number)(a);
    }
    var N = /* @__PURE__ */ function() {
      function a() {
        var e = this;
        F(this, a), v(this, "reset", function() {
          e.isInitialized = !1, e._testMode = !1, e._currentMeasurementId, e._hasLoadedGA = !1, e._isQueuing = !1, e._queueGtag = [];
        }), v(this, "_gtag", function() {
          for (var n = arguments.length, r = new Array(n), u = 0; u < n; u++)
            r[u] = arguments[u];
          e._testMode || e._isQueuing ? e._queueGtag.push(r) : o.default.apply(void 0, r);
        }), v(this, "_loadGA", function(n, r) {
          var u = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "https://www.googletagmanager.com/gtag/js";
          if (!(typeof window > "u" || typeof document > "u") && !e._hasLoadedGA) {
            var l = document.createElement("script");
            l.async = !0, l.src = "".concat(u, "?id=").concat(n), r && l.setAttribute("nonce", r), document.body.appendChild(l), window.dataLayer = window.dataLayer || [], window.gtag = function() {
              window.dataLayer.push(arguments);
            }, e._hasLoadedGA = !0;
          }
        }), v(this, "_toGtagOptions", function(n) {
          if (n) {
            var r = {
              // Old https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#cookieUpdate
              // New https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id#cookie_update
              cookieUpdate: "cookie_update",
              cookieExpires: "cookie_expires",
              cookieDomain: "cookie_domain",
              cookieFlags: "cookie_flags",
              // must be in set method?
              userId: "user_id",
              clientId: "client_id",
              anonymizeIp: "anonymize_ip",
              // https://support.google.com/analytics/answer/2853546?hl=en#zippy=%2Cin-this-article
              contentGroup1: "content_group1",
              contentGroup2: "content_group2",
              contentGroup3: "content_group3",
              contentGroup4: "content_group4",
              contentGroup5: "content_group5",
              // https://support.google.com/analytics/answer/9050852?hl=en
              allowAdFeatures: "allow_google_signals",
              allowAdPersonalizationSignals: "allow_ad_personalization_signals",
              nonInteraction: "non_interaction",
              page: "page_path",
              hitCallback: "event_callback"
            }, u = Object.entries(n).reduce(function(l, y) {
              var w = M(y, 2), _ = w[0], A = w[1];
              return r[_] ? l[r[_]] = A : l[_] = A, l;
            }, {});
            return u;
          }
        }), v(this, "initialize", function(n) {
          var r = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
          if (!n)
            throw new Error("Require GA_MEASUREMENT_ID");
          var u = typeof n == "string" ? [{
            trackingId: n
          }] : n;
          e._currentMeasurementId = u[0].trackingId;
          var l = r.gaOptions, y = r.gtagOptions, w = r.nonce, _ = r.testMode, A = _ === void 0 ? !1 : _, O = r.gtagUrl;
          if (e._testMode = A, A || e._loadGA(e._currentMeasurementId, w, O), e.isInitialized || (e._gtag("js", /* @__PURE__ */ new Date()), u.forEach(function($) {
            var ne = P(P(P({}, e._toGtagOptions(P(P({}, l), $.gaOptions))), y), $.gtagOptions);
            Object.keys(ne).length ? e._gtag("config", $.trackingId, ne) : e._gtag("config", $.trackingId);
          })), e.isInitialized = !0, !A) {
            var ee = m(e._queueGtag);
            for (e._queueGtag = [], e._isQueuing = !1; ee.length; ) {
              var te = ee.shift();
              e._gtag.apply(e, m(te)), te[0] === "get" && (e._isQueuing = !0);
            }
          }
        }), v(this, "set", function(n) {
          if (!n) {
            console.warn("`fieldsObject` is required in .set()");
            return;
          }
          if (h(n) !== "object") {
            console.warn("Expected `fieldsObject` arg to be an Object");
            return;
          }
          Object.keys(n).length === 0 && console.warn("empty `fieldsObject` given to .set()"), e._gaCommand("set", n);
        }), v(this, "_gaCommandSendEvent", function(n, r, u, l, y) {
          e._gtag("event", r, P(P({
            event_category: n,
            event_label: u,
            value: l
          }, y && {
            non_interaction: y.nonInteraction
          }), e._toGtagOptions(y)));
        }), v(this, "_gaCommandSendEventParameters", function() {
          for (var n = arguments.length, r = new Array(n), u = 0; u < n; u++)
            r[u] = arguments[u];
          if (typeof r[0] == "string")
            e._gaCommandSendEvent.apply(e, m(r.slice(1)));
          else {
            var l = r[0], y = l.eventCategory, w = l.eventAction, _ = l.eventLabel, A = l.eventValue;
            l.hitType;
            var O = c(l, s);
            e._gaCommandSendEvent(y, w, _, A, O);
          }
        }), v(this, "_gaCommandSendTiming", function(n, r, u, l) {
          e._gtag("event", "timing_complete", {
            name: r,
            value: u,
            event_category: n,
            event_label: l
          });
        }), v(this, "_gaCommandSendPageview", function(n, r) {
          if (r && Object.keys(r).length) {
            var u = e._toGtagOptions(r), l = u.title, y = u.location, w = c(u, d);
            e._gtag("event", "page_view", P(P(P(P({}, n && {
              page_path: n
            }), l && {
              page_title: l
            }), y && {
              page_location: y
            }), w));
          } else n ? e._gtag("event", "page_view", {
            page_path: n
          }) : e._gtag("event", "page_view");
        }), v(this, "_gaCommandSendPageviewParameters", function() {
          for (var n = arguments.length, r = new Array(n), u = 0; u < n; u++)
            r[u] = arguments[u];
          if (typeof r[0] == "string")
            e._gaCommandSendPageview.apply(e, m(r.slice(1)));
          else {
            var l = r[0], y = l.page;
            l.hitType;
            var w = c(l, g);
            e._gaCommandSendPageview(y, w);
          }
        }), v(this, "_gaCommandSend", function() {
          for (var n = arguments.length, r = new Array(n), u = 0; u < n; u++)
            r[u] = arguments[u];
          var l = typeof r[0] == "string" ? r[0] : r[0].hitType;
          switch (l) {
            case "event":
              e._gaCommandSendEventParameters.apply(e, r);
              break;
            case "pageview":
              e._gaCommandSendPageviewParameters.apply(e, r);
              break;
            case "timing":
              e._gaCommandSendTiming.apply(e, m(r.slice(1)));
              break;
            case "screenview":
            case "transaction":
            case "item":
            case "social":
            case "exception":
              console.warn("Unsupported send command: ".concat(l));
              break;
            default:
              console.warn("Send command doesn't exist: ".concat(l));
          }
        }), v(this, "_gaCommandSet", function() {
          for (var n = arguments.length, r = new Array(n), u = 0; u < n; u++)
            r[u] = arguments[u];
          typeof r[0] == "string" && (r[0] = v({}, r[0], r[1])), e._gtag("set", e._toGtagOptions(r[0]));
        }), v(this, "_gaCommand", function(n) {
          for (var r = arguments.length, u = new Array(r > 1 ? r - 1 : 0), l = 1; l < r; l++)
            u[l - 1] = arguments[l];
          switch (n) {
            case "send":
              e._gaCommandSend.apply(e, u);
              break;
            case "set":
              e._gaCommandSet.apply(e, u);
              break;
            default:
              console.warn("Command doesn't exist: ".concat(n));
          }
        }), v(this, "ga", function() {
          for (var n = arguments.length, r = new Array(n), u = 0; u < n; u++)
            r[u] = arguments[u];
          if (typeof r[0] == "string")
            e._gaCommand.apply(e, r);
          else {
            var l = r[0];
            e._gtag("get", e._currentMeasurementId, "client_id", function(y) {
              e._isQueuing = !1;
              var w = e._queueGtag;
              for (l({
                get: function(O) {
                  return O === "clientId" ? y : O === "trackingId" ? e._currentMeasurementId : O === "apiVersion" ? "1" : void 0;
                }
              }); w.length; ) {
                var _ = w.shift();
                e._gtag.apply(e, m(_));
              }
            }), e._isQueuing = !0;
          }
          return e.ga;
        }), v(this, "event", function(n, r) {
          if (typeof n == "string")
            e._gtag("event", n, e._toGtagOptions(r));
          else {
            var u = n.action, l = n.category, y = n.label, w = n.value, _ = n.nonInteraction, A = n.transport;
            if (!l || !u) {
              console.warn("args.category AND args.action are required in event()");
              return;
            }
            var O = {
              hitType: "event",
              eventCategory: (0, i.default)(l),
              eventAction: (0, i.default)(u)
            };
            y && (O.eventLabel = (0, i.default)(y)), typeof w < "u" && (typeof w != "number" ? console.warn("Expected `args.value` arg to be a Number.") : O.eventValue = w), typeof _ < "u" && (typeof _ != "boolean" ? console.warn("`args.nonInteraction` must be a boolean.") : O.nonInteraction = _), typeof A < "u" && (typeof A != "string" ? console.warn("`args.transport` must be a string.") : (["beacon", "xhr", "image"].indexOf(A) === -1 && console.warn("`args.transport` must be either one of these values: `beacon`, `xhr` or `image`"), O.transport = A)), e._gaCommand("send", O);
          }
        }), v(this, "send", function(n) {
          e._gaCommand("send", n);
        }), this.reset();
      }
      return k(a, [{
        key: "gtag",
        value: function() {
          this._gtag.apply(this, arguments);
        }
      }]), a;
    }();
    t.GA4 = N;
    var ve = new N();
    t.default = ve;
  }(B)), B;
}
var ce;
function Me() {
  return ce || (ce = 1, function(t) {
    function o(c) {
      "@babel/helpers - typeof";
      return o = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(p) {
        return typeof p;
      } : function(p) {
        return p && typeof Symbol == "function" && p.constructor === Symbol && p !== Symbol.prototype ? "symbol" : typeof p;
      }, o(c);
    }
    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t.default = t.ReactGAImplementation = void 0;
    var i = d(Re());
    function s(c) {
      if (typeof WeakMap != "function") return null;
      var p = /* @__PURE__ */ new WeakMap(), h = /* @__PURE__ */ new WeakMap();
      return (s = function(S) {
        return S ? h : p;
      })(c);
    }
    function d(c, p) {
      if (c && c.__esModule)
        return c;
      if (c === null || o(c) !== "object" && typeof c != "function")
        return { default: c };
      var h = s(p);
      if (h && h.has(c))
        return h.get(c);
      var m = {}, S = Object.defineProperty && Object.getOwnPropertyDescriptor;
      for (var b in c)
        if (b !== "default" && Object.prototype.hasOwnProperty.call(c, b)) {
          var E = S ? Object.getOwnPropertyDescriptor(c, b) : null;
          E && (E.get || E.set) ? Object.defineProperty(m, b, E) : m[b] = c[b];
        }
      return m.default = c, h && h.set(c, m), m;
    }
    var g = i.GA4;
    t.ReactGAImplementation = g;
    var f = i.default;
    t.default = f;
  }(V)), V;
}
var qe = Me();
const De = ({
  id: t,
  type: o,
  language: i,
  fullName: s,
  groupId: d,
  gaUserId: g,
  teacherId: f,
  email: c,
  emailDomain: p,
  gradeShortName: h,
  ownerInitials: m,
  superuserPretendMode: S,
  accountType: b
}) => ({
  type: o,
  id: t,
  clientId: je(),
  fullName: s,
  groupId: d,
  gaUserId: g,
  teacherId: f,
  pretendMode: S,
  language: i,
  emailDomain: p,
  gradeShortName: h,
  ownerInitials: m,
  email: c,
  accountType: b
}), le = (t, ...o) => {
  let i = { gaOptions: {} };
  return o.forEach((s) => {
    i = {
      ...i,
      ...s,
      gaOptions: {
        ...i == null ? void 0 : i.gaOptions,
        ...s == null ? void 0 : s.gaOptions,
        userId: t == null ? void 0 : t.gaUserId
      }
    };
  }), i;
}, je = () => {
  var t;
  return (t = document.cookie.split(";").find((o) => o.trim().startsWith("_ga="))) == null ? void 0 : t.split("=")[1].substring(6);
}, ke = (t) => ({
  gaOptions: {
    user_properties: {
      cd1: t.gaUserId,
      cd9: t.clientId,
      cd11: t.accountType
    }
  }
}), Ue = "";
class We extends qe.ReactGAImplementation {
  constructor() {
    super(...arguments);
    re(this, "config");
  }
  async init(i, s) {
    if (!i) throw new Error("Require GA_MEASUREMENT_ID");
    const d = await fetch(`${Ue}/api/auth`);
    if (!d.ok) return !1;
    const g = await d.json();
    if (this.config = De(g), this.isPretendMode)
      return console.debug("GA is disabled due to pretend mode."), !1;
    let f;
    const c = ke(this.config);
    return typeof s == "object" ? f = le(this.config, c, s) : f = le(
      this.config,
      c,
      s == null ? void 0 : s(this.config)
    ), this.initialize(i, f), this.isInitialized || console.error("GA initialized with an error"), this.isInitialized;
  }
  get isInit() {
    return this.isInitialized;
  }
  sendEvent(i, s) {
    (typeof i == "string" || Fe(i)) && this.event(i, s);
  }
  setDimension(i) {
    const s = new Map(Object.entries(i));
    this.setDimensions(s);
  }
  setDimensions(i) {
    this.gtag("set", "user_properties", Object.fromEntries(i));
  }
  sendEventAsSupervizor(i, s) {
    var d;
    ((d = this.config) == null ? void 0 : d.type) === "Supervizor" && this.sendEvent(i, s);
  }
  sendEventAsTeacher(i, s) {
    var d;
    ((d = this.config) == null ? void 0 : d.type) === "Teacher" && this.sendEvent(i, s);
  }
  get isPretendMode() {
    var i;
    return !!((i = this.config) != null && i.pretendMode);
  }
  pageView(i) {
    this.isInit && this.send({ hitType: "pageview", page: i });
  }
}
const fe = new We();
function Fe(t) {
  const o = t;
  return !!(o.action && o.category);
}
let Q;
const tt = (t, o) => {
  const [i, s] = J(), d = Y(!0);
  if (!Q && !t)
    throw new Error(
      "You must provide Google analytics ID in first invocation of useGA."
    );
  return q(() => {
    async function g() {
      !Q && t && (Q = fe.init(t, o)), await Q, d.current && s(fe);
    }
    g();
  }, [t, o]), q(() => () => {
    d.current = !1;
  }, []), i;
};
export {
  X as ApplicationName,
  Ze as buildURI,
  Xe as debounce,
  Ne as getElementsErrorId,
  Je as getPlainRosterType,
  et as getSeasonsErrorId,
  Be as useFetch,
  tt as useGoogleAnalytics,
  Oe as useLazyFetch,
  Ke as useOutside,
  He as useToggle,
  Ye as useWindowSize
};
